import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import CityPage from './pages/CityPage';
import ShopPage from './pages/ShopPage';
import RestaurantPage from './pages/RestaurantPage';
import OrderPage from './pages/OrderPage';
import PaymentConfirmPage from './pages/PaymentConfirmPage';
import PaymentFailPage from './pages/PaymentFailPage';
import AboutPage from './pages/AboutPage';
import AgreementPage from './pages/AgreementPage';
import PrivacyPage from './pages/PrivacyPage';
import RequestForUserDeletePage from './pages/RequestForUserDeletePage';
import PartnersPage from './pages/PartnersPage';
import NotFoundPage from './pages/NotFoundPage';
import CityRoute from './components/url-params/CityRoute';
import NotificationComponent from './components/NotificationComponent';  // Импортируйте компонент

import "./assets/fontawesome/css/all.min.css";
import "./theme/variables.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:city/*" element={<CityRoute><CityPage /></CityRoute>} />
          <Route path="/:city/shop/:shop" element={<CityRoute><ShopPage /></CityRoute>} />
          <Route path="/:city/:restaurant" element={<CityRoute><RestaurantPage /></CityRoute>} />
          <Route path="/:city/order" element={<CityRoute><OrderPage /></CityRoute>} />
          <Route path="/:city/payment-confirm" element={<CityRoute><PaymentConfirmPage /></CityRoute>} />
          <Route path="/:city/payment-fail" element={<CityRoute><PaymentFailPage /></CityRoute>} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/agreement" element={<AgreementPage />} />
          <Route path="/privacy-policy" element={<PrivacyPage />} />
          <Route path="/request-for-user-delete" element={<RequestForUserDeletePage />} /> {/* TODO: Update template */}
          <Route path="/partners" element={<PartnersPage />} />
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>

        <NotificationComponent />
      </div>
    </Router>
  );
}

export default App;
