import React, { useState } from 'react';
import Layout from '../components/layout/Layout';
import CustomInput from '../components/form/CustomInput';

import '../theme/partners-page.css';

function PartnersPage() {
  const [formData, setFormData] = useState({
    phone: '',
    name: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Layout>
      <section className="section-partner-banner bg-white">
        <div className="section-partner-banner__img-text">
          <h2 className="section-partner-banner__img-text-heading">Стать нашим партнёром</h2>
          <button className="btn-partner-scroll">Оставить заявку</button>
        </div>
        <div className="section-partner-banner__container">
          <div className="section-partner-banner__img"></div>
        </div>
      </section>
      <section className="section-partner-plus bg-white">
        <div className="container">
          <h2 className="section-partners__second-header">Плюсы от работы с агрегатором</h2>
          <ul className="row">
            <li className="section-partner-plus__item section-partner-plus__item-one col-sm-12 col-md-12 col-lg-6">
              <h3 className="section-partner-plus__heading">Продвижение ресторана</h3>
              <p className="section-partner-plus__text">
                Продвигаем Ваш ресторан на нашем сайте, мобильном приложении и социальных сетях
              </p>
            </li>
            <li className="section-partner-plus__item section-partner-plus__item-two col-sm-12 col-md-12 col-lg-6">
              <h3 className="section-partner-plus__heading">Удобный прием заказов</h3>
              <p className="section-partner-plus__text">Принять заказ можно на любом смартфоне</p>
            </li>
            <li className="section-partner-plus__item section-partner-plus__item-three col-sm-12 col-md-12 col-lg-6">
              <h3 className="section-partner-plus__heading">Рост продаж</h3>
              <p className="section-partner-plus__text">
                Средний прирост заказов у партнеров SunPanda +20% за первый месяц сотрудничества
              </p>
            </li>
            <li className="section-partner-plus__item section-partner-plus__item-four col-sm-12 col-md-12 col-lg-6">
              <h3 className="section-partner-plus__heading">Call - Центр</h3>
              <p className="section-partner-plus__text">Наш Call-Центр сможет помочь голодному клиенту с заказом</p>
            </li>
            <li className="section-partner-plus__item section-partner-plus__item-five col-sm-12 col-md-12 col-lg-6">
              <h3 className="section-partner-plus__heading">Готовый канал продаж</h3>
              <p className="section-partner-plus__text">
                Размещаем Ваше меню на своем сайте и мобильном приложении, а Вы платите только за полученные заказы
              </p>
            </li>
            <li className="section-partner-plus__item section-partner-plus__item-six col-sm-12 col-md-12 col-lg-6">
              <h3 className="section-partner-plus__heading">Ежемесячный отчет</h3>
              <p className="section-partner-plus__text">Отчёт о всех заказах</p>
            </li>
          </ul>
        </div>
      </section>
      <section className="section-partner-join">
        <div className="container">
          <h2 className="section-partners__second-header section-partner-join__second-heading">
            Как стать партнером
          </h2>
          <div className="row justify-content-around">
            <div className="col-xs-10 col-sm-7 col-md-3 col-lg-3 col-xl-3 q-pt-lg">
              <div className="row section-patner-center__block-item justify-content-center bg-white">
                <div className="col-3 d-flex align-items-end justify-content-end">
                  <img src="/assets/images/1.png" className="section-call-center__block-item-one" alt="Step 1" />
                </div>
                <div className="col-9 d-flex align-items-end">
                  <h1 className="section-partner-join__item-heading-one">Вы оставляете заявку</h1>
                </div>
                <div className="col-9 d-flex align-items-start q-pt-sm">
                  <p className="section-partner-join__item-text">На нашем сайте</p>
                </div>
              </div>
            </div>
            <div className="col-xs-10 col-sm-7 col-md-3 col-lg-3 col-xl-3 q-pt-lg">
              <div className="row section-patner-center__block-item justify-content-center bg-white">
                <div className="col-3 d-flex align-items-end justify-content-end">
                  <img src="/assets/images/2.png" className="section-call-center__block-item-one" alt="Step 2" />
                </div>
                <div className="col-9 d-flex align-items-end">
                  <h1 className="section-partner-join__item-heading-two">Заключаем договор</h1>
                </div>
                <div className="col-9 d-flex align-items-start q-pt-sm">
                  <p className="section-partner-join__item-text">
                    Мы создаем страницу Вашего ресторана на сервисе + заключаем договор
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xs-10 col-sm-7 col-md-3 col-lg-3 col-xl-3 q-pt-lg">
              <div className="row section-patner-center__block-item justify-content-center bg-white">
                <div className="col-3 col-sm-3 d-flex align-items-end justify-content-end">
                  <img src="/assets/images/3.png" className="section-call-center__block-item-one" alt="Step 3" />
                </div>
                <div className="col-9 col-sm-9 d-flex align-items-end">
                  <h3 className="section-partner-join__item-heading-three">Вы получаете от нас поток заказов</h3>
                </div>
                <div className="col-9 d-flex align-items-start q-pt-sm">
                  <p className="section-partner-join__item-text">
                    Размещаем Ваше меню на своем сайте и мобильном приложении, а Вы платите только за полученные заказы
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-partner-form bg-white">
        <h2 className="section-partners__second-header">Оставить заявку</h2>
        <div className="section-partner-form__block row justify-content-center">
          <div className="section-partner-form__block-min col-sm-12 col-md-4 col-lg-4">
            <img src="/assets/images/form.jpg" alt="Form" className="section-partner-form__block-min-img" />
          </div>
          <div className="section-partner-form__block-min section-partner-form__block-min-form col-sm-6 col-md-4 col-lg-4">
            <form className="q-form section-partner-form__block-input" id="partnerForm">
              <div className='row'>
                <div className="col-12 q-pa-sm mb-3">
                  <CustomInput
                    label="Введите Ваше имя"
                    bottomLabel="Введите Ваше имя"
                    id="fullName"
                    name="name"
                    isRequired={true}
                    onChange={handleInputChange} />
                </div>
                <div className="col-12 q-pa-sm mb-3">
                  <CustomInput
                    label="Ваш номер телефна"
                    id="phoneNumber"
                    name="phone"
                    isRequired={true}
                    onChange={handleInputChange} />
                </div>
              </div>
              <div className="section-partner-form__checkbox justify-content-center">
                <p className="section-partner-form__checkbox-text">При отправке формы, Вы соглашаетесь на обработку данных!</p>
              </div>
              <button className="btn-partner">Отправить</button>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default PartnersPage;
