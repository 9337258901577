import { parentApi } from '../../fetcher';

export const orderApi = parentApi.injectEndpoints({
    endpoints: (builder) => ({
        createOrder: builder.mutation({
            query: (orderData) => ({
                url: `${orderData.city}/SiteOrderAPI`,
                method: 'POST',
                body: orderData,
            }),
        }),
        sendVerificationPhone: builder.mutation({
            query: ({ city, phone }) => ({
                url: `${city}/VerificationPhone`,
                method: 'GET',
                params: { phone },
            }),
        }),
        getCoupons: builder.mutation({
            query: ({ city, phone, category_id, shop_id, restaurant_id, type, totalPrice }) => ({
                url: `${city}/GetCoupons`,
                method: 'POST',
                body: {
                    phone,
                    category_id,
                    totalPrice: totalPrice,
                    [type === 'shop' ? 'shop_id' : 'restaurant_id']: type === 'shop' ? shop_id : restaurant_id,
                },
            }),
        }),
        getPaymentMethods: builder.mutation({
            query: ({ city, restaurant_id, type }) => ({
                url: `${city}/payment-methods/${restaurant_id}?type=${type}`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useCreateOrderMutation,
    useSendVerificationPhoneMutation,
    useGetCouponsMutation,
    useGetPaymentMethodsMutation,
} = orderApi;
