import React, { useState, useEffect } from 'react';
import { getCityData } from '../utils/city-data';
import { Link } from 'react-router-dom';
import { useCity } from '../contexts/CityContext';
import {
    useCreateOrderMutation,
    useSendVerificationPhoneMutation,
    useGetCouponsMutation,
    useGetPaymentMethodsMutation
} from '../modules/orders/api';
import { useNavigate } from 'react-router-dom';
import CartList from '../components/cart/CartList';
import CustomInput from '../components/form/CustomInput';
import eventBus from '../eventBus';
import Layout from '../components/layout/Layout';
import Select from 'react-select';
import AlertModal from '../components/modal/AlertModal';
import PaymentQRModal from '../components/modal/PaymentQRModal';
import useCartTotalPrice from '../hooks/useCartTotalPrice';

function OrderPage() {
    const city = useCity();
    const [createOrder] = useCreateOrderMutation();
    const [isOrderProcessing, setIsOrderProcessing] = useState(false);
    const [sendVerificationPhone] = useSendVerificationPhoneMutation();
    const navigate = useNavigate();
    const { total, couponTotal, deliveryFee } = useCartTotalPrice();
    const [deliveryMode, setDeliveryMode] = useState('delivery'); // 'delivery' или 'pickup'
    const [restaurantCity, setRestaurantCity] = useState('');
    const [restaurantName, setRestaurantName] = useState('');
    const [restaurantId, setRestaurantId] = useState('');
    const [isUsingShop, setIsUsingShop] = useState(false);
    const [isDeliveryAvailable, setIsDeliveryAvailable] = useState(true);
    const [alertModalData, setAlertModalData] = useState(null);
    const [paymentQRModalData, setPaymentQRModalData] = useState(null);

    // Verification code
    const [secretCode, setSecretCode] = useState(null);
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');

    // Promo code
    const [coupons, setCoupons] = useState([]);
    const [isPromoCodeEnabled, setIsPromoCodeEnabled] = useState(false);
    const [isPromoCorrect, setIsPromoCorrect] = useState(false);
    const [isPromoWrong, setIsPromoWrong] = useState(false);
    const [activeCoupon, setActiveCoupon] = useState(null);
    const [sendGetCoupons] = useGetCouponsMutation();

    // Payment methods
    const [sendGetPaymentMethods] = useGetPaymentMethodsMutation();
    const [paymentMethods, setPaymentMethods] = useState([]);

    const cityData = getCityData(restaurantCity ? restaurantCity : city);

    const [formData, setFormData] = useState({
        id: null,
        city: cityData.url,
        phone: '',
        name: '',
        order_type: 'Site',
        order_comment: '',
        order_status_id: '1',
        tax: 0,
        hint: null,
        delivery_fee: 0,
        address: '',
        entrance: '',
        domophone: '',
        floor: '',
        apartment: '',
        longitude: null,
        latitude: null,
        foods: [],
        payment: {},
        success_url: `${window.location.origin}/City-${cityData.urlName}/payment-confirm`,
        fail_url: `${window.location.origin}/City-${cityData.urlName}/payment-fail`,
        coupon: {
            id: null,
            code: '',
            discount: 0,
            discount_type: null,
            valid: null,
            discountables: [],
        },
    });

    const handleOrderSubmit = async (e) => {
        e.preventDefault();

        try {
            if (!formData) {
                return;
            }

            updateCart();

            setIsOrderProcessing(true);

            if (!isFormDataValid(formData)) {
                console.error("Некоторые обязательные поля не заполнены.", formData, deliveryMode);
                return;
            }

            const response = await createOrder(formData);

            if (response.error) {
                throw response.error;
            }

            let requestData = response.data;

            if (requestData.data) {
                if (requestData.data.hasOwnProperty('payment_url') && requestData.data.payment_url) {
                    window.location = requestData.data.payment_url;
                } else if (requestData.data.hasOwnProperty('payment_qr') && requestData.data.payment_qr) {
                    const orderId = requestData.data.id;
                    const userId = requestData.data.user_id;
                    const orderCity = cityData.urlName;

                    sessionStorage.setItem('orderData', JSON.stringify({ orderId, userId, orderCity }));

                    setPaymentQRModalData({
                        svgCode: requestData.data.payment_qr,
                        onClose: handleAlertClose,
                    });
                } else {
                    clearCart();
                    requestData.success && navigate(`/City-${restaurantCity}/payment-confirm`);
                }
            }
        } catch (error) {
            if (error.data) {
                setAlertModalData({
                    title: 'Ошибка!',
                    message: 'Во время оформления заказа произошла ошибка. Пожалуйста, повторите позднее.',
                    onClose: handleAlertClose,
                });

            }
            // navigate(`/City-${restaurantCity}/payment-fail`);
        } finally {
            setIsOrderProcessing(false);
        }
    };

    const handleAlertClose = () => {
        setAlertModalData(null);
        setPaymentQRModalData(null);
        window.location.reload(true);
    };

    const handleSendCode = async () => {
        try {
            const response = await sendVerificationPhone({
                city: cityData.url,
                phone: formData.phone
            });
            setSecretCode(response.data.SecretCode);
            setIsCodeSent(true);
        } catch (error) { }
    };

    const handleConfirmCode = async () => {
        try {
            if (parseInt(verificationCode, 10) === parseInt(secretCode, 10)) {
                localStorage.setItem('savedPhoneNumber', formData.phone);

                handleSuccessfulVerification();
            } else {
            }
        } catch (error) { }
    };

    const handleSuccessfulVerification = async () => {
        setIsCodeSent(false);
        setIsUserLoggedIn(true);
    };

    const handleExit = () => {
        setIsCodeSent(false);
        setIsUserLoggedIn(false);
        setCoupons([]);
        setIsPromoCodeEnabled(false);
        setVerificationCode('');

        localStorage.removeItem('savedPhoneNumber');

        setFormData({
            ...formData,
            phone: '',
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'phone') {
            // Удаляем начальный '7' (если есть)
            const cleanedPhoneNumber = value.replace(/[^\d]/g, '').replace(/^7/, '');
            setFormData((prevData) => ({
                ...prevData,
                [name]: cleanedPhoneNumber,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handlePaymentMethodChange = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            payment: {
                ...prevData.payment,
                method: selectedOption.value,
            },
        }));
    };

    const handleCouponCodeChange = (event) => {
        const enteredCode = event.target.value;

        const appliedCoupon = coupons.find((coupon) => coupon.code === enteredCode);
        setActiveCoupon(appliedCoupon);

        if (!appliedCoupon) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                coupon: {
                    id: null,
                    code: enteredCode,
                    discount: 0,
                    discount_type: null,
                    valid: false,
                    discountables: [],
                },
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                coupon: {
                    id: appliedCoupon.id,
                    code: appliedCoupon.code,
                    discount: appliedCoupon.discount,
                    discount_type: appliedCoupon.discount_type,
                    valid: true,
                    discountables: [],
                },
            }));
        }

        eventBus.publish('couponApplied', appliedCoupon);

        setIsPromoCorrect(!!appliedCoupon);
        setIsPromoWrong(!appliedCoupon);
    };

    const handleDeliveryModeChange = (mode) => {
        setDeliveryMode(mode);
        localStorage.setItem('deliveryMode', deliveryMode);

        setFormData({
            ...formData,
            address: null,
            entrance: null,
            domophone: null,
            floor: null,
            apartment: null,
            payment: mode === 'pickup' ? {
                id: null,
                status: null,
                method: 'Pay on Pickup'
            } : null,
        });

        eventBus.publish('deliveryModeChanged', mode);
    };

    const getBusinessInfo = () => {
        const restaurantInfo = JSON.parse(localStorage.getItem('cartRestaurantInfo')) || false;
        const shopInfo = JSON.parse(localStorage.getItem('cartShopInfo')) || false;
        const businessInfo = restaurantInfo ? restaurantInfo : shopInfo;

        return businessInfo;
    }

    const getCoupons = async () => {
        if (isUserLoggedIn === false) {
            return;
        }

        try {
            const storedCart = JSON.parse(localStorage.getItem('cartItems')) || [];
            const firstCartItem = storedCart[0];

            if (total === 0) return;

            const totalWithoutDiscount = total + couponTotal;

            const { data } = await sendGetCoupons({
                city: cityData.url,
                phone: formData.phone,
                category_id: firstCartItem.category_id,
                [isUsingShop ? 'shop_id' : 'restaurant_id']: restaurantId,
                type: isUsingShop ? 'shop' : 'restaurant',
                totalPrice: totalWithoutDiscount
            });

            const couponsArray = Object.values(data);

            setCoupons(couponsArray);
            setIsPromoCodeEnabled(true);
        } catch (error) {
            console.error(error);
        }
    }

    const clearCart = () => {
        localStorage.removeItem('cartItems');
        localStorage.removeItem('cartRestaurantInfo');
        localStorage.removeItem('cartShopInfo');
        localStorage.removeItem('deliveryMode');

        eventBus.publish('cartCleared');
    };

    const updateCart = () => {
        const businessInfo = getBusinessInfo();

        if (!businessInfo) {
            return;
        }

        const storedCart = JSON.parse(localStorage.getItem('cartItems')) || [];
        loadCartIntoFormData(storedCart, !!businessInfo.shopName ? 'shop' : 'restaurant', activeCoupon);
    }

    const loadCartIntoFormData = (cartItems, businessType, coupon = null) => {
        const businessKeys = {
            shop: {
                formDataKey: 'products',
                extrasKey: 'extras',
                itemIdKey: 'product_id',
                otherFormDataKey: 'foods',
            },
            restaurant: {
                formDataKey: 'foods',
                extrasKey: 'extras',
                itemIdKey: 'food_id',
                otherFormDataKey: 'products',
            },
        };

        const {
            formDataKey,
            extrasKey,
            itemIdKey,
            otherFormDataKey,
        } = businessKeys[businessType];

        let totalDiscount = 0;

        if (coupon && coupon.discount) {
            if (coupon.discount_type === "percent") {
                totalDiscount = coupon.discount / 100 * cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0);
            } else if (coupon.discount_type === "fixed") {
                totalDiscount = coupon.discount;
            }
        }

        // Расчет обновленной цены для каждого товара с учетом скидки
        const updatedItems = cartItems.map((cartItem) => {
            const extrasArray = cartItem.extras.reduce((acc, extra) => {
                for (let i = 0; i < extra.count; i++) {
                    acc.push(extra.id);
                }
                return acc;
            }, []);

            let discountedPrice = cartItem.price - totalDiscount / cartItems.length / cartItem.quantity;
            return {
                id: null,
                price: discountedPrice,
                quantity: cartItem.quantity,
                [itemIdKey]: cartItem[itemIdKey],
                [extrasKey]: extrasArray,
            };
        });

        setFormData((prevData) => ({
            ...prevData,
            [formDataKey]: updatedItems,
            [otherFormDataKey]: undefined,
            delivery_fee: deliveryFee,
        }));
    };

    const loadPaymentMethods = async (restaurant_id, isShop) => {
        const { data: paymentMethodsData } = await sendGetPaymentMethods({
            city: cityData.url,
            restaurant_id: restaurant_id,
            type: isShop ? 'shop' : 'restaurant',
        });
        setPaymentMethods(paymentMethodsData?.data);
    }

    const loadBusinessInfo = () => {
        const businessInfo = getBusinessInfo();

        if (!businessInfo) {
            return;
        }

        const name = businessInfo.restaurantName || businessInfo.shopName;
        const city = businessInfo.restaurantCity || businessInfo.shopCity;
        const id = businessInfo.restaurant_id || businessInfo.shop_id;

        setRestaurantName(name);
        setRestaurantCity(city);
        setRestaurantId(id);
        setIsUsingShop(!!businessInfo.shopName);

        loadPaymentMethods(id, !!businessInfo.shopName);

        setDeliveryMode(localStorage.getItem('deliveryMode') || 'delivery');

        if (businessInfo.available_for_delivery === false && deliveryMode !== 'pickup') {
            handleDeliveryModeChange('pickup');
        } else {
            handleDeliveryModeChange(deliveryMode);
        }

        const storedCart = JSON.parse(localStorage.getItem('cartItems')) || [];
        loadCartIntoFormData(storedCart, !!businessInfo.shopName ? 'shop' : 'restaurant', activeCoupon);

        setIsDeliveryAvailable(businessInfo.available_for_delivery);
    };

    const checkSavedPhoneNumber = () => {
        const savedPhoneNumber = localStorage.getItem('savedPhoneNumber');
        if (savedPhoneNumber) {
            setFormData((prevData) => ({
                ...prevData,
                phone: savedPhoneNumber,
            }));

            handleSuccessfulVerification();

            return savedPhoneNumber;
        }

        return false;
    }

    const isFormDataValid = (data) => {
        const requiredFields = ['address', 'phone', 'name', 'payment.method'];

        if (deliveryMode === 'pickup') {
            requiredFields.splice(0, 1);
        }

        if (!isUserLoggedIn) {
            return false;
        }

        if (isCodeSent) {
            requiredFields.push('coupon.code');
        }

        for (let field of requiredFields) {
            let fieldValue = data;

            field.split('.').forEach(key => fieldValue = fieldValue[key]);
            if (!fieldValue) {
                return false;
            }
        }
        return true;
    };

    useEffect(() => {
        loadBusinessInfo();

        const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        setFormData((prevData) => ({
            ...prevData,
            is_mobile: isMobileDevice,
        }));

        updateCart();

        checkSavedPhoneNumber();

        eventBus.subscribe('cartItemAdded', updateCart);
        eventBus.subscribe('cartItemRemoved', updateCart);
        eventBus.subscribe('cartItemQuantityUpdated', updateCart);
        eventBus.subscribe('couponApplied', updateCart);

        return () => {
            eventBus.unsubscribe('cartItemAdded', updateCart);
            eventBus.unsubscribe('cartItemRemoved', updateCart);
            eventBus.unsubscribe('cartItemQuantityUpdated', updateCart);
            eventBus.unsubscribe('couponApplied', updateCart);
        };
    }, [restaurantId, isUsingShop, activeCoupon, deliveryFee]);

    useEffect(() => {
        const getCouponsIfLoggedIn = async () => {
            if (isUserLoggedIn) {
                getCoupons();
            }
        };

        getCouponsIfLoggedIn();
    }, [total, isUserLoggedIn]);

    return (
        <Layout city={restaurantCity}>
            <section className="section_order">
                <div className="q-pb-sm q-pt-sm">
                    {isUsingShop ? (
                        <Link to={`/City-${restaurantCity}/shop/shop-${restaurantName}`}>
                            <i className="fas fa-chevron-left"></i> Вернуться в магазин
                        </Link>
                    ) : (
                        <Link to={`/City-${restaurantCity}/shop-${restaurantName}`}>
                            <i className="fas fa-chevron-left"></i> Вернуться в ресторан
                        </Link>
                    )}
                </div>
                <h1 className="section_order_header">Оформление заказа</h1>
                {/* Заглушка */}
                <div className={`justify-content-center align-items-center ${isOrderProcessing ? 'd-flex' : 'd-none'}`}>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <svg className="q-spinner text-deep-orange" width="200px" height="200px" viewBox="0 0 100 100"
                                preserveAspectRatio="xMidYMid" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <path fill="none" stroke="currentColor" strokeWidth="5" strokeMiterlimit="10"
                                        d="M58.4,51.7c-0.9-0.9-1.4-2-1.4-2.3s0.5-0.4,1.4-1.4 C70.8,43.8,79.8,30.5,80,15.5H70H30H20c0.2,15,9.2,28.1,21.6,32.3c0.9,0.9,1.4,1.2,1.4,1.5s-0.5,1.6-1.4,2.5 C29.2,56.1,20.2,69.5,20,85.5h10h40h10C79.8,69.5,70.8,55.9,58.4,51.7z">
                                    </path>
                                    <clipPath id="uil-hourglass-clip1">
                                        <rect x="15" y="20" width="70" height="25">
                                            <animate attributeName="height" from="25" to="0" dur="1s" repeatCount="indefinite"
                                                values="25;0;0" keyTimes="0;0.5;1"></animate>
                                            <animate attributeName="y" from="20" to="45" dur="1s" repeatCount="indefinite"
                                                values="20;45;45" keyTimes="0;0.5;1"></animate>
                                        </rect>
                                    </clipPath>
                                    <clipPath id="uil-hourglass-clip2">
                                        <rect x="15" y="55" width="70" height="25">
                                            <animate attributeName="height" from="0" to="25" dur="1s" repeatCount="indefinite"
                                                values="0;25;25" keyTimes="0;0.5;1"></animate>
                                            <animate attributeName="y" from="80" to="55" dur="1s" repeatCount="indefinite"
                                                values="80;55;55" keyTimes="0;0.5;1"></animate>
                                        </rect>
                                    </clipPath>
                                    <path d="M29,23c3.1,11.4,11.3,19.5,21,19.5S67.9,34.4,71,23H29z"
                                        clipPath="url(#uil-hourglass-clip1)" fill="currentColor"></path>
                                    <path d="M71.6,78c-3-11.6-11.5-20-21.5-20s-18.5,8.4-21.5,20H71.6z"
                                        clipPath="url(#uil-hourglass-clip2)" fill="currentColor"></path>
                                    <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="180 50 50"
                                        repeatCount="indefinite" dur="1s" values="0 50 50;0 50 50;180 50 50" keyTimes="0;0.7;1">
                                    </animateTransform>
                                </g>
                            </svg>
                        </div>
                        <div className="col-12 text-center">
                            <h3>Заказ оформляется</h3>
                        </div>
                    </div>
                </div>
                <div className={`${isOrderProcessing ? 'd-none' : ''} row justify-content-center`}>
                    {/* Форма оформления заказа */}
                    <div className="bg-grey-1 col-sm-12 col-md-6 q-mb-lg" style={{ maxWidth: '600px' }}>
                        <div className="q-pa-md d-flex justify-content-center">
                            <div className="q-btn-group row no-wrap q-btn-group--spread q-btn-toggle">
                                <button
                                    className={`q-btn q-btn-item non-selectable no-outline q-btn--standard q-btn--rectangle ${deliveryMode === 'delivery' ? 'bg-purple text-white' : 'bg-white text-black'
                                        } q-hoverable ${!isDeliveryAvailable ? 'q-btn--disabled' : ''}`}
                                    onClick={() => handleDeliveryModeChange('delivery')}
                                    {...(!isDeliveryAvailable && { disabled: true })}
                                    type="button"
                                >
                                    <span className="q-focus-helper"></span>
                                    <span className="q-btn__content text-center col align-items-center q-anchor--skip justify-content-center row">
                                        <span className="block">Доставка</span>
                                    </span>
                                </button>
                                <button
                                    className={`q-btn q-btn-item non-selectable no-outline q-btn--standard q-btn--rectangle ${deliveryMode === 'pickup' ? 'bg-purple text-white' : 'bg-white text-black'
                                        } q-hoverable`}
                                    onClick={() => handleDeliveryModeChange('pickup')}
                                    type="button"
                                >
                                    <span className="q-focus-helper"></span>
                                    <span className="q-btn__content text-center col align-items-center q-anchor--skip justify-content-center row">
                                        <span className="block">Навынос</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="q-pa-md justify-content-center">
                            {isDeliveryAvailable === false && <div className="text-center">Ресторан временно работает только на самовывоз</div>}

                            <form onSubmit={handleOrderSubmit}>
                                <div className="row">
                                    <div className="col-12 q-pa-sm mb-3">
                                        <CustomInput
                                            label="Введите Ваше имя"
                                            bottomLabel="Введите Ваше имя"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            isRequired={true}
                                            isWrong={isOrderProcessing && formData.name.trim() === ''}
                                            onChange={handleInputChange} />
                                    </div>
                                    <div className="col-12 q-pa-sm mb-3 mt-3">
                                        <div className="row">
                                            <div className="col-12 col-sm-5" style={{ minWidth: '165px' }}>
                                                <CustomInput
                                                    label="Ваш номер телефна"
                                                    id="phone"
                                                    name="phone"
                                                    value={formData.phone}
                                                    additionalClass={isUserLoggedIn ? 'q-field--filled' : ''}
                                                    inputMask="+7(999) 999-99-99"
                                                    isDisabled={isCodeSent || isUserLoggedIn}
                                                    isRequired={true}
                                                    onChange={handleInputChange} />
                                            </div>
                                            <div className="col-12 col-sm-7 q-pl-sm q-pt-sm">
                                                <button
                                                    className="q-btn q-btn-item non-selectable no-outline q-btn--standard q-btn--rectangle q-hoverable"
                                                    tabIndex="0" type="button"
                                                    onClick={isUserLoggedIn ? handleExit : handleSendCode}>
                                                    <span className="q-focus-helper"></span>
                                                    <span className="q-btn__content text-center col align-items-center q-anchor--skip justify-content-center row">
                                                        {isUserLoggedIn ? 'Выход' : 'Выслать код подтверждения'}
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {isCodeSent && (
                                        <div className="row q-pa-sm align-items-center">
                                            <div className="col-3">
                                                <CustomInput
                                                    label="Введите код"
                                                    id="code"
                                                    name="code"
                                                    inputMask="9999"
                                                    value={verificationCode}
                                                    onChange={(e) => setVerificationCode(e.target.value)} />
                                            </div>
                                            <div className="col-3 q-pl-sm q-pt-xs">
                                                <button
                                                    className="q-btn q-btn-item non-selectable no-outline q-btn--standard q-btn--rectangle q-btn--actionable q-focusable q-hoverable"
                                                    tabIndex="0"
                                                    type="button"
                                                    onClick={handleConfirmCode}
                                                >
                                                    <span className="q-focus-helper"></span>
                                                    <span className="q-btn__content text-center col items-center q-anchor--skip justify-content-center row">Подтвердить</span>
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    {deliveryMode === 'delivery' && (
                                        <div className='p-0'>
                                            <div className="col-12 q-pa-sm mb-3">
                                                <CustomInput
                                                    label="Адрес доставки"
                                                    id="address"
                                                    name="address"
                                                    value={formData.address}
                                                    isRequired={true}
                                                    onChange={handleInputChange} />
                                            </div>

                                            <div className="col-12 q-pa-sm mb-3">
                                                <div className="row">
                                                    <CustomInput
                                                        label="Подъезд"
                                                        id="entrance"
                                                        name="entrance"
                                                        type="number"
                                                        additionalClass="col-sm-3"
                                                        value={formData.entrance}
                                                        onChange={handleInputChange} />
                                                    <CustomInput
                                                        label="Домофон"
                                                        id="domophone"
                                                        name="domophone"
                                                        type="number"
                                                        additionalClass="col-sm-3"
                                                        value={formData.domophone}
                                                        onChange={handleInputChange} />
                                                    <CustomInput
                                                        label="Этаж"
                                                        id="floor"
                                                        name="floor"
                                                        type="number"
                                                        additionalClass="col-sm-3"
                                                        value={formData.floor}
                                                        onChange={handleInputChange} />
                                                    <CustomInput
                                                        label="Квартира"
                                                        id="apartment"
                                                        name="apartment"
                                                        type="number"
                                                        additionalClass="col-sm-3"
                                                        value={formData.apartment}
                                                        onChange={handleInputChange} />
                                                </div>
                                            </div>

                                            <div className="col-12 q-pa-sm mb-3">
                                                <Select
                                                    label="Способ оплаты"
                                                    placeholder="Способ оплаты"
                                                    className="custom-react-select-container row"
                                                    classNamePrefix="custom-react-select"
                                                    required={true}
                                                    options={paymentMethods || []}
                                                    onChange={handlePaymentMethodChange} />
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-12 q-pa-sm mb-3">
                                        <CustomInput
                                            label="Комментарий"
                                            id="order_comment"
                                            name="order_comment"
                                            additionalClass="q-textarea q-field--filled"
                                            isTextarea={true}
                                            value={formData.order_comment}
                                            onChange={handleInputChange} />
                                    </div>

                                    <div className="col-12 q-pa-sm mb-3">
                                        <CustomInput
                                            label="Промокод"
                                            id="сoupon.code"
                                            name="coupon.code"
                                            autocomplete='off'
                                            isCorrect={isPromoCorrect}
                                            isWrong={isPromoWrong}
                                            isDisabled={!isPromoCodeEnabled}
                                            value={formData.coupon.code}
                                            onChange={handleCouponCodeChange}
                                        />
                                    </div>

                                    <div className="col-12 d-flex justify-content-center">
                                        <button
                                            className="q-btn q-btn-item non-selectable no-outline q-btn--standard q-btn--rectangle bg-orange text-white q-hoverable q-mt-md"
                                            tabIndex="0" type="submit">
                                            <span className="q-focus-helper"></span>
                                            <span
                                                className="q-btn__content text-center col align-items-center q-anchor--skip justify-content-center row">
                                                <span className="block">Заказать</span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    {/* Корзина */}
                    <div className="col-sm-12 col-md-4 d-flex justify-content-center">
                        <div style={{ maxWidth: '300px' }}>
                            <CartList />
                            <div className="q-item q-item-type row no-wrap">
                                <div className="q-item__section d-flex q-item__section--main justify-content-center">
                                    <div className="q-item__label">
                                        <h1>Итого</h1>
                                    </div>
                                </div>
                                <div className="q-item__section d-flex q-item__section--side justify-content-end">
                                    <div className="q-item__label">
                                        <h1>{total} ₽</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {alertModalData && <AlertModal title={alertModalData.title} message={alertModalData.message} onClose={alertModalData.onClose} />}
            {paymentQRModalData && <PaymentQRModal qrCodeSvg={paymentQRModalData.svgCode} onClose={paymentQRModalData.onClose} />}
        </Layout>
    );
}

export default OrderPage;
