import React from 'react';
import Layout from '../components/layout/Layout';

function RequestForUserDeletePage() {
  return (
    <Layout>
      <section className="section-partner-form bg-white">
        <h2 className="section-partners__second-header text-center">Запрос на удаление учетной записи</h2>
        <div className="row justify-content-center">
          <div className="section-partner-form__block col-sm-6 col-md-4 col-lg-4">
            <form action="/">
              <div className="form-floating">
                <select className="form-select" id="city" name="city" aria-label="City">
                  <option selected>Ахтубинск</option>
                  <option value="1">Волгодонск</option>
                  <option value="2">Ейск</option>
                  <option value="3">Знаменск</option>
                  <option value="4">Каменск-Шахтинский</option>
                  <option value="5">Крымск</option>
                  <option value="6">Новошахтинск</option>
                  <option value="7">Сарапул</option>
                </select>
                <label htmlFor="city">Город</label>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-floating mb-3">
                    <input type="text" name="phone" className="form-control" id="phone" />
                    <label htmlFor="phone">Ваш номер телефона</label>
                  </div>
                </div>
                <div className="col-6">
                  <button className="q-btn q-btn-item" tabIndex="0" type="button">
                    Выслать код подтверждения
                  </button>
                </div>
              </div>
              <button type="submit" className="btn btn-partner">
                Запросить удаление данных пользователя
              </button>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default RequestForUserDeletePage;
